const apiPaths = {
  AUTH: {
    LOGIN: 'auth/bo/login',
    GENERAL_LOGIN: 'auth/login',
    REFRESH: 'auth/bo/refresh-token',
    LOGOUT: 'auth/bo/logout',
    PARAMS: 'userparams', //TODO define api
    PERMISSIONS: 'bopermissions/getUserPermissions',
    RECOVERY: 'recoveryPassword',
    UPDATE: 'recoveryPassword/changepassword',
    USER: 'users/bo/user/info',
    REGISTER_MANAGER: '/auth/bo/register-manager',
    PLATFORMS: '/platform',
  },
  ACCESS: 'menu/access',
  COMBO: 'combodata',
  CALL: {
    ACTIONS: '/actions',
    ASSIGN_POINTS_SELL_OUT: '/userresource/sellOut',
    ASSIGN_POINTS: '/userresource/uploadMedia',
    BOOK_USERS: '/userresource/bookusers',
    BOPERMISSIONS: '/bopermissions',
    BOROLES: '/boroles',
    BOROLESPERMISSIONS: '/borolespermissions',
    BOUSERS: '/bousers',
    BOUSERSROLES: '/bousersroles',
    BRANDS: '/magento/products/brands',
    CACHE_MANAGER_ALL: '/cacheManager/clearAll',
    CACHE_MANAGER: '/cacheManager',
    CENTER_USERS: '/center/usersListPlatformStatus',
    CENTER: '/center',
    CENTERS_ACTIVE: '/center/active',
    CENTERS_BULK: '/center/bulkcenters',
    CENTERS_USER_ASSIGN: '/usersCenter/assign',
    CENTERS_USER_DE_ASSIGN: '/usersCenter/deassign',
    CHALLENGE_FROM_TYPE: '/challenge/type',
    CHALLENGE_POINTS: '/resource/score',
    CHALLENGE_USERS_PROGRESS: '/userresource/challenge',
    CHALLENGE_USERS_RESTRICTIONS: '/challengeRestriction/users',
    CHALLENGE: '/challenge',
    CHALLENGE_EXIST_BY_CRITERIA: '/challenge/checkIfExistByCriteria',
    CHALLENGEI18N: '/challengei18n',
    CHALLENGEL10N: '/challengel10n',
    CHALLENGERESOURCETYPE: '/challengeresourcetype',
    CHALLENGERESTRICTION: '/challengerestriction',
    CHALLENGETEMPLATE: '/challengetemplate',
    CHALLENGETYPE: '/challengetype',
    CHALLENGETYPEI18N: '/challengetypei18n',
    CONTENT_PAGE_VIEW: '/contentPagei18n',
    CONTENT_PAGE: '/contentpage',
    COUNTRY: '/country',
    CREATE_FULL_PAGE: '/contentpage/create-full-page',
    CREATE_FULL: '/challenge/create-full',
    CUSTOMER_BULK_CREATE_TRANSACTION:
      '/points/customer/backofficeBulkCreateTransaction',
    CUSTOMER_CONSUME_POINTS: 'points/customer/backofficeConsumePoints',
    CUSTOMER_ORDER_DETAILS: '/magento/customer/orderDetails',
    CUSTOMER_REMOVE_CART_ITEMS: '/magento/customer/cart/items',
    DEACTIVATE_QRS: 'antifraud/deactivate-qrs',
    DELETE_DERMO_USER: '/userRoles',
    DELETE_MASSIVE_USERS: '/users/massive-delete-users',
    DELETE_TOKEN_USER: '/users/token',
    EVENT_ATTEND: '/userresource/eventAttend',
    EXCEPTIONPOINTSCENTER: '/exceptionpointscenter',
    EXCEPTIONPOINTSUSERS: '/exceptionpointsusers',
    EXPERIENCE_PRODUCTS: '/experience-products',
    GET_SELL_OUT_RANGES: 'userResource/sellOut/getRanges',
    IMPERSONATE: '/auth/bo/impersonate',
    LABEL_REDEEM_PRODUCTS_CLEAN: '/labelRedeemProducts/cleanLabel',
    LANDING_PRODUCT_MASSIVE: '/landingProduct/massive',
    LANDING_PRODUCT: '/landingProduct',
    LANGUAGES: '/languages',
    LIKE_POINTS: '/score/challenge',
    LIST_VALUES_I18N: '/listOfValuesI18n',
    LIST_VALUES: '/list',
    MAIL_PREVIEW: '/mailTemplates/preview',
    MAIL_SEND_PREVIEW: '/mail/sendMail',
    MAIL_SURVEY: '/mailTemplates/survey',
    MARKETING_MATERIALS_I18N: '/marketingMaterialsI18n',
    MARKETING_MATERIALS: '/marketingMaterials',
    MENU: '/menu',
    MENUI18N: '/menui18n',
    PAGE_COMPONENT_RECURSIVE: '/contentPageComponent/recursive',
    PAGE_COMPONENT: '/contentPageComponent',
    PARAMETERS_TYPES: '/parametersTypes',
    PARAMETERS: '/parameters',
    PEARL_REVIEWER_EMAIL: 'userresource/pearlReviewerEmail',
    PEARLS: '/userResource/pearlStatus',
    PERMISSIONS: '/permissions',
    PERMISSIONSI18N: '/permissionsi18n',
    PREVIEW: '/auth/preview',
    QR_PRODUCT_BULK: '/qrProduct/bulkUpdate',
    REPLICATE_VALIDATE: '/replicate/validate',
    REPLICATE: '/replicate',
    RESET_PASSWORD_FROM_BO_PIN: '/recoveryPassword/liww',
    RESET_PASSWORD_FROM_BO: '/recoveryPassword/liww',
    RESOURCE_DEPENDENCY: 'resource/dependency',
    RESOURCE_FROM_TEMPLATE: '/challenge/resource',
    RESOURCE_RECURSIVE: '/resource/recursive',
    RESOURCE_WITH_DETAILS: '/resource/details',
    RESOURCE: '/resource',
    RESOURCEDETAIL: '/resourcedetail',
    RESOURCEDI18N: '/resourcedi18n',
    RESOURCEI18N: '/resourcei18n',
    RESOURCERESTRICTION: '/resourcerestriction',
    RESOURCES_USERS_PROGRESS: '/userresource/resources',
    RESOURCETYPE: '/resourcetype',
    RESOURCETYPEDETAIL: '/resourcetypedetail',
    RESOURCETYPEDI18N: '/resourcetypedi18n',
    RESOURCETYPEI18N: '/resourcetypei18n',
    ROLES: '/roles',
    ROLESI18N: '/rolesi18n',
    ROLESPERMISSIONS: '/rolespermissions',
    SCORE_CHALLENGE: '/score/challenge',
    SCORE_RESOURCE: '/score/resource',
    SCORE: '/score',
    SELL_IN_REPORT: '/challenge/report/sellIn',
    SOFT_DELETE_USER: '/users/soft-delete',
    SPECIALTYI18N: '/specialtyI18n',
    SUBSCRIBE: '/userresource/subscribe',
    SURVEY_MAIL: '/survey/associate/mail',
    SURVEY: '/survey',
    TAG: '/tag',
    TEMPLATECHRE: '/templatechre',
    TRAINING_REPORT: '/challenge/report/training',
    TRANSMUTE_TO: '/challenge/transmuteTo',
    UNSUBSCRIBE: '/userresource/unsubscribe',
    UPDATE_DERMO_SALE_POINT: '/dermoCenterRegionPos/setSalePoint',
    UPDATE_DERMO_USER: '/userRoles/bo/setUserStatus',
    USER_INFO_UUID: '/users/info/uuid',
    USER_REASSIGN_CENTER: '/users/reassignCenter',
    USERRESOURCE: '/userresource',
    USERS_BULK: '/users/bulkusers',
    USERS_NO_CENTER: '/users/orphan/center',
    USERS: '/users',
    USERSCENTER_UPDATE_ROLE_SALESFORCE: '/salesforce/updateUserRole',
    USERSCENTER_UPDATE_ROLE: '/usersCenter/updateUserRole',
    USERSCENTER: '/userscenter',
    USERSROLES: '/usersroles',
    VALIDATE_USER_EMAIL: '/auth/validate-user',
    VALIDATED_USERS: '/users/bo/validated-users',
    CATALOG_LIMITS: '/magento/customers/getcataloglimits',
    CATALOG_LIMITS_USERS: '/magento/customers/userswithexceptions',
  },
  COMBODATA: {
    CENTER: 'center',
    CHALLENGE_TYPE: 'challenge_type',
    CHALLENGE: 'challenge',
    COUNTRY: 'country',
    LANGUAGES: 'languages',
    MENU: 'menu',
    PERMISSIONS: 'permissions',
    PLATFORMS: 'boplatforms',
    QR_PRODUCT_BU: 'qr_product_bu',
    RESOURCE: 'resource',
    RESOURCE_DETAIL: 'resource_detail',
    RESOURCE_TYPE: 'resource_type',
    RESOURCE_TYPE_DETAIL: 'resource_type_detail',
    ROLES: 'roles',
    SCORE: 'score',
    SURVEYS: 'surveys',
    CONTEST_TAGS: 'contest_tags',
    EXPERIENCE_TAGS: 'experience_tags',
    ARN_SURVEYS: 'arn_surveys',
    USERS: 'users',
    POINTS_ENTITY_TYPE: 'pointsEntityType',
    POINTS_ENTITY_ID: 'pointsEntityId',
    PARAMETERS_TYPES: 'boParametersType',
  },
  WEBEAT: {
    MENU_CHILD: '/menu/type-childs',
    CALL: {
      CHALLENGE_TYPE: '/challenge-type',
      RESOURCE_TYPE: '/resource-type',
      RESOURCE: 'challenge/{idChallenge}/resource',
      ASSIGN_POINTS: 'challenge/{idChallenge}/assignPoints',
      CUSTOMER_ASSIGN_POINTS_BY_EMAILS: 'customer/assign-points-by-emails',
      DATA_REPLICA_TARGETS: '/data-replica/targets',
      DATA_REPLICA_CREATE_ORDER: '/data-replica/create-order',
      QUIZ_QUESTION_TYPE: '/question-types',
    },
    DERMOADVISOR: {
      VALIDATE: '/dermo-validation/validate',
      INVALIDATE: '/dermo-validation/invalidate',
      REJECT: '/dermo-validation/reject',
      USERS: '/user',
    },
    PLATFORMS: 'platform/platform-selector',
    GET_RESOURCE_DEPENDENCIES_STATES: 'resource/get-states-by-type',
    UPDATE_RESOURCE_DEPENDENCIES: 'resource/update-resource-dependencies',
  },
  UPLOAD: 'upload',
  UPLOAD_MEDIA: 'upload/media-file',
  UPLOAD_ARN_TOKEN: 'arnSurveyToken/file',
  CONSUMABLES: {
    GET_ALL_CUSTOMER_COUPONS: '/my-bonus',
    GET_PRODUCT_LANDING: '/get-product-landing',
    SCAN_QR: '/bonus/scan-qr',
    SCAN_LANDING_VALIDATE: '/scan-landing/validate',
  },
};

export default apiPaths;
